.header-navbar {
    padding-top: 0;
    padding-bottom: 0;
}

html .content {
    padding-top: 2rem !important;
    margin-left: 0 !important;
}

.btn {
    word-break: normal;
}
