html {
    font-family: sans-serif;
    background-size: cover;
    background-color: #e8e4e4;
    position: relative;
    height: auto;
    min-height: 100%;
    font-size: 16px;
}

#mainRegister {
    background-repeat: no-repeat;
    // background-position: calc(-50%) top;
    background-size: contain;
    background-color: #591ca8;
    // background-image: url("https://aog-conference-staging.gms.church/images/59fa358e6a672227a5e9296590dc3590.png");
    // background-image: url(https://aog-conf.b-cdn.net/aog-bg_512.png);
    // background-image: url(https://cf-assets.gms.church/59c38a5a-ad45-4396-079a-a256f1ab0b00/960);
    // @media only screen and (min-width: 720px) {
    //     background-image: url(https://cf-assets.gms.church/36e69c8d-afa2-4710-1b96-29bc29e14000/960);
    // }
    position: relative;
    width: 100%;
    padding-top: 0rem;
    padding-bottom: 1rem;
    z-index: 0;
    min-height: 100vh;
}

#mainRegister {
    > .app-content {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
        max-width: 480px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    overflow: hidden;
}

body {
    background-color: transparent !important;
    height: 100vh;
}

.table {
    thead > tr > th {
        background-color: transparent !important;
    }
}
.va-wrapper {
    margin-bottom: 1rem;
    .va-number {
        font-weight: bold;
        font-size: 1.3em;
    }
}

.btn-submit {
    @apply bg-primary;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    &:disabled {
        color: white;
        @apply bg-primary;
    }
}

.card {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    .bg-object-1 {
        position: relative;
        left: -40%;
        opacity: 0.7;
    }
    .bg-object-2 {
        position: relative;
        top: 250px;
        right: -50%;
        opacity: 0.5;
    }
}

footer {
    @apply py-3;
    background-color: black;
    text-align: center;
    .line {
        @apply w-24;
        display: inline-block;
        border-top: 2px solid white;
    }
}
