.btn-link-no-style {
    line-height: inherit;
    vertical-align: inherit;
    padding: inherit;
}
.alert-danger {
    background-color: #f8d7da !important;
}

.card {
    @apply mb-4 mt-4;
}
