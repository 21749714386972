.vnumgf {
    color: #d93025;
    padding-left: 0.25em;
}

#mainOperator {
    position: relative;
    width: 100%;
    height: 100vh;
}

#mainOperator {
    > .app-content {
        padding-top: 2rem !important;
        padding-bottom: 4rem !important;
        margin-left: auto;
        margin-right: auto;
    }
}
